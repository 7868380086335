<template>
  <div class="mobile-container">
    <div class="page-header-item">
      <div class="content-item">
        <div class="back img-cover" @click="$router.go(-1)"><img src="../../assets/images/back-icon-white.png" /></div>
      </div>
    </div>
    <div class="page-bg-item"></div>
    <div class="status-item">
      <div class="icon img-cover">
        <img v-if="orderInfo.OrderStatus == 4" src="../../assets/images/order-status-icon-4.png" />
        <img v-else src="../../assets/images/order-status-icon.png" />
      </div>
      <div class="info">
        <div class="status">订单{{ orderStatusObj[orderInfo.OrderStatus] }}</div>
        <div class="tips">{{ orderInfo.OrderStatus == 4 ? '您的订单已完成，请留意处理结果' : '您的订单正在处理中，请留意处理结果' }}</div>
      </div>
    </div>
    <div class="order-info-item">
      <div class="goods-info">
        <div class="img img-cover">
          <img v-if="orderInfo.Img" :src="orderInfo.Img" />
          <img v-else src="../../assets/images/no-image-icon.png" />
        </div>
        <div class="info">
          <div class="name-item">
            <div class="name">{{ orderInfo.GoodsName }}</div>
            <div class="money">¥ {{ Math.round(orderInfo.SellingPrice * 100000) / 100000 }}</div>
          </div>
          <div class="desc-item">
            <div class="params">
              <div v-if="orderInfo.BuyParams && orderInfo.BuyParams.length > 0">{{ orderInfo.BuyParams[0].value }}</div>
            </div>
            <div class="count">x {{ orderInfo.BuyNumber }}</div>
          </div>
        </div>
      </div>
      <div v-if="orderInfo.GoodsType == 1" class="count-item">
        <div class="item">开始 {{ orderInfo.StartNum }}</div>·
        <div class="item">当前 {{ orderInfo.CurrentNum }}</div>·
        <div class="item">完成 {{ orderInfo.CurrentNum - orderInfo.StartNum }}</div>
      </div>
      <div class="money-item">实付款 <span>¥</span> <span class="money">{{ Math.round(orderInfo.Amount * 100000) / 100000 }}</span></div>
    </div>
    <div class="params-list-item">
      <div class="item" v-for="(item, index) in orderInfo.BuyParams" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="detail">{{ item.value }}</div>
        <div class="btn" @click="handleCopy(item.value)">复制</div>
      </div>
    </div>
    <div class="order-detail-item">
      <div class="title-item">下单信息</div>
      <div class="info-item">
        <div class="name">订单编号</div>
        <div class="value">{{ orderInfo.OrderSN }}</div>
        <div class="btn" @click="handleCopy(orderInfo.OrderSN)">复制</div>
      </div>
      <div class="info-item">
        <div class="name">下单时间</div>
        <div class="value" style="color: #030304;">{{ $TOOL.dateFormat(orderInfo.CreateAt * 1000, 'yyyy-MM-dd hh:mm') }}</div>
      </div>
      <!-- <div class="info-item">
        <div class="name">下单备注</div>
        <div class="value">{{ orderInfo.OrderRemark }}</div>
      </div> -->
      <div class="info-item" v-if="orderInfo.RefundAmount">
        <div class="name">退款金额</div>
        <div class="value">{{ orderInfo.RefundAmount }}</div>
      </div>
      <div class="info-item" v-if="orderInfo.RefundNumber">
        <div class="name">退款数量</div>
        <div class="value">{{ orderInfo.RefundNumber }}</div>
      </div>
      <div class="info-item" v-if="orderInfo.BackReason">
        <div class="name">退款原因</div>
        <div class="value">{{ orderInfo.BackReason }}</div>
      </div>
      <div class="info-item" v-if="orderInfo.OrderRemark">
        <div class="name">订单备注</div>
        <div class="value" style="white-space: pre-wrap;">{{ orderInfo.OrderRemark }}</div>
      </div>
    </div>
    <div v-if="orderInfo.CardCodeIds && orderInfo.CardCodeIds.length > 0" class="order-detail-item">
      <div class="title-item">卡密</div>
      <div v-for="(item, index) in orderInfo.CardCodeIds" :key="index" class="info-item">
        <div class="value" style="text-align: left;">{{ item }}</div>
        <div class="btn" @click="handleCopy(item)">复制</div>
      </div>
    </div>
    <div class="order-detail-item">
      <div class="title-item">订单记录</div>
      <div v-for="(item, index) in orderInfo.Logs" :key="index" class="info-item">
        <div class="name">{{ item.content }}</div>
        <div class="value">{{ item.createdAt }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "OrderDetail",
  data() {
    return {
      id: '',
      orderInfo: {},
      orderStatusObj: { '-1': '待付款', '1': '处理中', '2': '处理中', '3': '处理中', '4': '已完成', '5': '退单中', '6': '已退单', '7': '已退款', '8': '处理中' },
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.handleGetOrderDetail()
    }
  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleGetOrderDetail() {
      const { id } = this
      var res = await this.$API.orderDetail.post({
        Id: id
      })
      if (res.error == 0) {
        res.info.Logs = JSON.parse(res.info.Logs || '[]')
        res.info.BuyParams = JSON.parse(res.info.BuyParams || '[]')
        res.info.CardCodeIds = res.info.CardCodeIds.split(',')
        res.info.CardCodeIds = res.info.CardCodeIds.filter(item => item != '')
        this.orderInfo = res.info
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
  padding-bottom: .9rem;
}
.page-header-item {
  height: .88rem;
  .content-item {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 50%;
    width: var(--max-width);
    margin-left: var(---max-width-50);
    height: .88rem;
    overflow: hidden;
    background-color: #1399ff;
    .back {
      cursor: pointer;
      width: .6rem;
      height: .6rem;
      position: absolute;
      left: .24rem;
      top: .14rem;
    }
  }
}
.page-bg-item {
  width: 100%;
  height: 4.72rem;
  background: linear-gradient( 180deg, #1399ff 0%, rgba(19, 153, 255, 0) 100%);
}
.status-item {
  margin: -4.72rem auto 0;
  width: 6.86rem;
  height: 1.14rem;
  padding-top: .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    padding: .12rem .12rem 0;
    width: .64rem;
    height: .64rem;
  }
  .info {
    flex: 1;
    color: #242941;
    .status {
      font-size: .36rem;
      font-weight: bold;
    }
    .tips {
      font-size: .24rem;
    }
  }
}
.order-info-item {
  margin: 0 .32rem;
  background-color: #fff;
  border-radius: .12rem;
  padding: .24rem;
  .goods-info {
    display: flex;
    justify-content: space-between;
    .img {
      width: 1.44rem;
      height: 1.44rem;
      border-radius: .24rem;
      overflow: hidden;
    }
    .info {
      width: 4.7rem;
      .name-item {
        padding-top: .08rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #151d2b;
        line-height: .4rem;
        font-size: .28rem;
        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
        }
        .money {
          margin-left: .24rem;
          font-weight: bold;
          font-size: .32rem;
        }
      }
      .desc-item {
        padding-top: .08rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ccc;
        line-height: .34rem;
        font-size: .24rem;
        .params {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .count {
          margin-left: .24rem;
        }
      }
    }
  }
  .count-item {
    margin-top: .24rem;
    width: 100%;
    height: .48rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #151d2b;
    background-color: #f7f7f7;
    border-radius: .08rem;
    .item {
      font-size: .22rem;
      margin: 0 .06rem;
    }
  }
  .money-item {
    margin-top: .32rem;
    font-size: .24rem;
    color: #151d2b;
    span {
      font-weight: bold;
      &.money {
        font-size: .36rem;
      }
    }
  }
}
.params-list-item {
  margin: .24rem .32rem 0;
  background-color: #fff;
  border-radius: .12rem;
  padding: .12rem .24rem;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .28rem;
    line-height: .34rem;
    padding: .16rem 0;
    .name {
      color: #11113a;
      font-size: .3rem;
      font-weight: bold;
    }
    .detail {
      flex: 1;
      word-break: break-all;
      padding: 0 .3rem;
      text-align: right;
      color: #b3b3b3;
    }
    .btn {
      cursor: pointer;
      color: #1399ff;
    }
  }
}
.order-detail-item {
  margin: .24rem .32rem 0;
  background-color: #fff;
  border-radius: .12rem;
  padding: .12rem .24rem .16rem;
  .title-item {
    height: .7rem;
    line-height: .7rem;
    padding-top: .1rem;
    border-bottom: .02rem solid #f7f7f7;
    font-size: .3rem;
    color: #151d2b;
    font-weight: bold;
    margin-bottom: .16rem;
  }
  .info-item {
    width: 100%;
    // height: .72rem;
    padding: .16rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .28rem;
    line-height: .4rem;
    .name {
      color: #030304;
    }
    .value {
      flex: 1;
      text-align: right;
      padding-left: .24rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #b3b3b3;
    }
    .btn {
      margin-left: .24rem;
      color: #1399ff;
    }
  }
}
</style>